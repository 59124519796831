var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),_c('Tree',{staticClass:"left-tree",attrs:{"data":_vm.treeData},on:{"on-select-change":_vm.onSelectChange}}),_c('div',{staticClass:"right-list"},[_c('CtmsAction',[(
					_vm.isChild &&
					_vm.systemActionPermissions.indexOf('btn_setting_dictionary_add') >
						-1 &&
					_vm.isShowAdd
				)?_c('Button',{on:{"click":_vm.handleAddShow}},[_vm._v("\n\t\t\t\t新增\n\t\t\t")]):_vm._e(),(
					_vm.systemActionPermissions.indexOf('btn_setting_dictionary_add') > -1
				)?_c('Button',{on:{"click":_vm.handleSort}},[_vm._v("\n\t\t\t\t排序\n\t\t\t")]):_vm._e(),_c('div',{staticClass:"custom-search-wrapper"},[_c('span',[_vm._v("状态：")]),_c('Select',{staticStyle:{"width":"200px"},attrs:{"transfer":true,"placeholder":"请选择状态","clearable":""},on:{"on-change":_vm.getSearchListData},model:{value:(_vm.searchParams.isDel),callback:function ($$v) {_vm.$set(_vm.searchParams, "isDel", $$v)},expression:"searchParams.isDel"}},[_c('Option',{attrs:{"value":0}},[_vm._v("启用")]),_c('Option',{attrs:{"value":1}},[_vm._v("禁用")])],1)],1)],1),_c('CtmsDataGrid',{attrs:{"height":_vm.dataGridHeight,"columns":_vm.allColumns,"data":_vm.searchListData,"page":false}})],1),_c('Add',{attrs:{"visible":_vm.addVisible,"parentNode":_vm.parentNode,"isShowAdd":_vm.isShowAdd,"updateId":_vm.updateId},on:{"onCancel":function($event){_vm.addVisible = false},"onOk":_vm.handleAddOk}}),_c('ViewDetail',{attrs:{"visible":_vm.viewVisible,"viewId":_vm.viewId},on:{"onCancel":function($event){_vm.viewVisible = false}}}),_c('DictionaryOrder',{attrs:{"visible":_vm.addSortVisible,"dictionaryData":_vm.searchListData},on:{"onCancel":function($event){_vm.addSortVisible = false},"onOk":_vm.handleProcessSortOk}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }