<template>
	<Modal
		title="查看数据字典"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="viewData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="上级名称" prop="parentName">
						{{ viewData.parentName || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="名称">
						{{ viewData.name || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="简称/值">
						{{ viewData.nickname || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="更新人">
						{{ viewData.updatedBy || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="更新时间">
						{{ viewData.updatedTime || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="描述">
						{{ viewData.descr || "--" }}
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/dictionary"

const { apiGet } = api
export default {
	name: "ViewDetail",
	props: ["viewId", "visible"],
	data() {
		return {
			viewData: {},
			loading: false
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.viewData = {}
				if (this.viewId) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.viewId })
						if (res) {
							this.viewData = {
								...this.viewData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	}
}
</script>

<style scoped></style>
