<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="上级名称" prop="parentName">
						<Input v-model="formData.parentName" :disabled="true" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="名称" prop="name">
						<Input v-model="formData.name" placeholder="请输入名称" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="简称/值" prop="nickname">
						<Input
							v-model="formData.nickname"
							:disabled="updateId && !isShowAdd"
							placeholder="请输入简称/值"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="描述" prop="descr">
						<Input
							v-model="formData.descr"
							type="textarea"
							placeholder="请输入描述"
							:rows="5"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/dictionary"

const { apiGet, apiAdd, apiUpdate } = api

export default {
	name: "Add",
	props: ["visible", "parentNode", "updateId", "isShowAdd"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				name: {
					required: true,
					message: "名称不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.title = "新增数据字典"
				this.formData = {}
				this.formData.parentName = this.parentNode.name
				if (this.updateId) {
					this.title = "修改数据字典"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.updateId })
						if (res) {
							this.formData = {
								...this.formData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							res = await apiUpdate({
								...this.formData,
								type: this.parentNode.obj.type,
								parentId: this.parentNode.id,
								id: this.updateId
							})
						} else {
							res = await apiAdd({
								...this.formData,
								type: this.parentNode.obj.type,
								parentId: this.parentNode.id
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>
