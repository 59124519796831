<template>
	<Modal
		title="数据字典排序"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div v-if="dictionaryData.length">
			<p style="font-size: 14px; color: red; margin-bottom: 10px">拖拽排序</p>
			<Draggable
				v-model="dictionaryData"
				:options="{
					draggable: '.sort-item',
					animation: 150,
					ghostClass: 'active',
					dragClass: 'active'
				}"
			>
				<p
					v-for="(item, index) in dictionaryData"
					class="sort-item"
					:key="index"
				>
					{{ item.name }}
				</p>
			</Draggable>
		</div>
		<div v-else>
			<p style="padding-top: 150px; text-align: center; font-size: 14px">
				暂无数据
			</p>
		</div>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel"> 取消 </Button>
			<Button type="primary" @click="onOk"> 确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/dictionary"
import Draggable from "vuedraggable"

const { apiDictionarySort } = api
export default {
	name: "ProcessOrder",
	props: ["visible", "dictionaryData"],
	components: {
		Draggable
	},
	data() {
		return {
			loading: false
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$asyncDo(async () => {
				this.loading = true
				const params = this.dictionaryData.map((item, index) => ({
					sort: index + 1,
					dictionaryId: item.id
				}))
				const res = await apiDictionarySort({
					dictionarySortList: params
				})
				if (res) {
					this.$Message.success("操作成功")
					this.$emit("onOk")
				}
				this.loading = false
			})
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.dictionarySortList = this.dictionarySortList || []
			}
		}
	}
}
</script>

<style scoped lang="less">
.sort-item {
	margin: 15px auto;
	font-size: 14px;
	padding: 5px 10px;
	color: #848586;
	border: 1px solid #a6a6ae;
	border-radius: 5px;
	cursor: pointer;
	&.active {
		color: #fd9f21;
		box-shadow: 0px 2px 10px 0px rgba(253, 150, 46, 0.2);
		border: solid 1px #fd9f21;
	}
}
</style>
